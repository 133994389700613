<template>
  <div class="container">
    <PublicHeader :totalName="$t('msgbank').noticeTitle" />
    <div class="content">
      <van-list
        class="list-box"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <div class="item" v-for="(item, index) in list" :key="index" @click="toDetail(item)">
          <div class="msg">{{ item.Title }}</div>
          <div class="dataTime">{{ item.Time | getLocalTime }}</div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import userAPI from '@/api/user'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      list: [],
      form: {
        page: 1,
        size: 10,
        LanguageType: ''
      },
      loading: false,
      finished: false
    }
  },
  mounted() {
    this.getLang(localStorage.getItem('language'))
  },
  methods: {
    async getLang(lang) {
      let LanguageType = ''
      if (lang === 'zh') {
        LanguageType = 'Chinese'
      } else if (lang === 'en') {
        LanguageType = 'English'
      } else if (lang === 'ty') {
        LanguageType = 'Thai'
      } else if (lang === 'ft') {
        LanguageType = 'Traditional'
      } else if (lang === 'jp') {
        LanguageType = 'Japanese'
      } else if (lang === 'hy') {
        LanguageType = 'Korea'
      } else if (lang === 'yn') {
        LanguageType = 'Indonesia'
      } else if (lang === 'de') {
        LanguageType = 'German'
      } else if (lang === 'fr') {
        LanguageType = 'French'
      } else if (lang === 'ar') {
        LanguageType = 'Arabic'
      } else if (lang === 'ru') {
        LanguageType = 'Russian'
      } else if (lang === 'es') {
        LanguageType = 'Spanish'
      } else if (lang === 'yu') {
        LanguageType = 'Vietnam'
      }
      this.form.LanguageType = LanguageType
    },
    async getList() {
      const res = await userAPI.notices(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }
      this.loading = false
    },
    toDetail(val) {
      this.$router.push({
        name: 'Detail',
        query: {
          title: val.Title,
          info: val.Content
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 44px;
  .content {
    margin: 10px;
    border-radius: 4px;
    overflow: hidden;
    .list-box {
      height: calc(100vh - 64px);
      overflow: scroll;
    }
    .item {
      border-radius: 5px;
      background: #17181e;
      padding: 10px;
      margin-bottom: 10px;
      .msg {
        font-size: 16px;
        margin-bottom: 5px;
        line-height: 25px;
      }
      .dataTime {
        margin-top: 5px;
        color: #d5d2d2;
      }
    }
  }
}
</style>
